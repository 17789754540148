import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from '../../context/translationContext';
import { Form, Table, Typography, Select, Modal,  Button } from 'antd';
import { useEffect } from "react";
import { getSiteLiveStatus, deleteInstantControl, getLiveStatusV2, sendInstantControl } from "../../elements/apis/controlApis";
import { SaveIcon, RefreshIcon, UndoIcon, EditIconInstantControl, NotAvailableIcon, DeleteOverrideIcon, SaveIconForSE, EditIconInstantControlForSE, RefreshIconForSE, DeleteOverrideIconForSE, UndoIconForSE, UpArrowIcon, DownArrowIcon, SaveIconForMCD, EditIconInstantControlForMCD, RefreshIconForMCD, DeleteOverrideIconForMCD, UndoIconForMCD } from "../../assests/icon";
import { toast } from "react-toastify";
import moment from "moment";
import _isEmpty from "lodash/isEmpty";
import { toastSuccess,  toastError } from "../../toast/toast";
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import CloseOutlined from "../../imgs/CloseOutlined.svg"
import CloseOutlinedSE from "../../imgs/CloseOutlinedSE.svg"
import CloseOutlinedMCD from "../../imgs/CloseOutlinedMCD.svg"
import CloseBlackOutlined from "../../imgs/CloseBlackOutlined.svg";
import { translateDatasource } from '../../lib/utils';
import "../../views/InstantControl/style.css"
import { CustomDropDown, CustomInputNumber } from '../../views/InstantControl/newInstantControl';
import { SITE_OVERRIDE_DURATIONS } from '../../lib/constants';

function useForceUpdate(){
    const [value, setValue] = useState(0); 
    return () => setValue(value => value + 1);
}
const DEFAULT_UNIT = 'metric'

const EquipmentTable = ({equipmentType}) => {

    const { getTranslation } = useTranslation();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [apiResponse, setAPIResponse] = useState(null)
    const [dropDownValues, setDropDownValues] = useState({})
    const [showRevertModal, setShowRevertModal] = useState(false)
    const [revertRecordObj, setRevertRecord] = useState({})
    const [isDeleteOverrideVisible, setIsDeleteOverrideVisible] = useState(false)
    const [overrideDurationModalShow, setOverrideDurationModalShow] = useState(false)
    const [stateForOverride, setStateForOverride] = useState({})
    const [equipmentForOverride, setEquipmentForOverride] = useState(null)
    const [overrideDurationType, setOverrideDurationType] = useState(null)
    let editingKeyRef = useRef('')
    let dataRef = useRef([])
    let columns = useRef([])
    const isStaff = useSelector(state => state.appStatus.isStaff)
    const cpmReducer = useSelector(state => state.cpmReducer)
    const userData = useSelector(state => state.appStatus)
    const clientEquipmentMapping = useSelector(state => state.appStatus.appPermissions.clientEquipmentMapping)
    const overrideDuration = clientEquipmentMapping['data']['controlOverrideDuration']
    let isControlOverride = !clientEquipmentMapping?.['data']?.['instantControlEnabled'];
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
    const dashboard = useSelector(state => state.dashboard)    
    const streamData = cpmReducer.siteStreamData?.[equipmentType];
    const siteOverrideDuration = useRef(null);

    const isEditing = (record) => {
        if(record && record['key'] === editingKeyRef.current) return true
        else    return false
    };

    const mergedColumns = columns.current.map((col) => {
        if (!col.editable) {
            return { ...col, title: getTranslation(col.title) };
        }
        return {
            ...col,
            title: getTranslation(col.title),
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                // properties: col.properties ? col.properties : '',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const refreshRecord = (record) => {
        refreshEquipment(record.equipment)
    }

    const refreshEquipment = async(equipment) => {
        try {
            let resp = await getLiveStatusV2({ equipment_name: equipment, client_name: cpmReducer.siteName })
            if (resp.data) {
                let obj = resp.data[0]
                let returnEquipmentName = obj['name']
                let equipment_state = obj['state'] || "";
                let returnCanRevert = isControlOverride ? !obj['override_status'] : obj['indefinite']
                let existingData = dataRef.current.filter(obj => obj['equipment'] === returnEquipmentName)

                for(let i = 0 ; i < obj['current_status'].length ; i++){
                    let currentStatusData = obj['current_status'][i];
                    let statusData
                    if(currentStatusData.hasOwnProperty("live_device_mode")){
                        statusData = currentStatusData['live_device_mode']
                    }
                    let streamName = currentStatusData['name']
                    let streamValue = currentStatusData['value']
                    let streamType = currentStatusData['type'];
                    let streamUnit = currentStatusData['unit'] === "Celsius" ? "°C" : currentStatusData['unit']
                    let streamProperties = currentStatusData['properties']
                    let toggleData
                    if(currentStatusData.hasOwnProperty('toggle'))
                        toggleData = currentStatusData['toggle']
                    let toggleStreamName, toggleStreamValue, toggleStreamUnit = '', toggleStreamProperties
                    if(toggleData){
                        toggleStreamName = toggleData['name']
                        toggleStreamValue = toggleData['value']
                        toggleStreamUnit = toggleData['unit'] === "Celsius" ? "°C" : toggleData['unit']
                        toggleStreamProperties = toggleData['properties']
                    }
                    streamName = streamName ? streamName.replace(/ |\//g, "_") : streamName
                    toggleStreamName = toggleStreamName ? toggleStreamName.replace(/ |\//g, "_") : toggleStreamName

                    if(streamName.includes("ON_OFF")){
                        existingData[0][streamName] = (streamValue === "Old Data" || streamValue === "No Data" || !!streamType?.includes("monitoring")) ? streamValue : Number(streamValue) === 1 ? "ON" : "OFF"
                        existingData[0][`${streamName}_Properties`] = streamProperties
                    }else if(streamName && (!currentStatusData?.properties?.input_string_list?.includes(streamValue) && streamValue !== "Auto" && streamValue !== "Manual" 
                        && streamValue !== "OFF" && streamValue !== "ON")){
                        existingData[0][streamName] = (streamValue === "Old Data" || streamValue === "No Data" || (!!streamType?.includes("monitoring") && isNaN(streamValue)) ) ? streamValue : `${Number(streamValue)} ${streamUnit}`
                        existingData[0][`${streamName}_Properties`] = streamProperties
                    }else if(streamName){
                        existingData[0][streamName] = streamValue
                        existingData[0][`${streamName}_Properties`] = streamProperties
                    }
                    if(toggleStreamName){
                        existingData[0][toggleStreamName] = (toggleStreamValue === "Old Data" || toggleStreamValue === "No Data" || (!!streamType?.includes("monitoring") && isNaN(toggleStreamValue) )) ? toggleStreamValue : `${Number(toggleStreamValue)} ${toggleStreamUnit}`
                        existingData[0][`${streamName}_Properties`] = toggleStreamProperties
                    }
                    if(statusData && (streamName.includes("VFD") || streamName.includes("Set_Differential_Pressure") || streamName.includes("Set_Pressure") || streamName.includes("Approach_Set_Temperature") )){
                        existingData[0]['VFD_Status'] = statusData
                        existingData[0][`${streamName}_Properties`] = streamProperties
                    }else if(statusData && !streamName.includes("VFD") && existingData[0].hasOwnProperty("Valve_Status")){
                        existingData[0]['Valve_Status'] = statusData
                        existingData[0][`${streamName}_Properties`] = streamProperties
                    }
                    // else if(statusData && !streamName.includes("VFD") && existingData[0].hasOwnProperty("Set_Differential_Pressure_Status"))
                    //     existingData[0]['Set_Differential_Pressure_Status'] = statusData
                    
                }
                Object.keys(existingData[0]).forEach(data => {
                    existingData[0][data] = existingData[0][data] === null ? 'NA' : existingData[0][data] === "Old Data" || existingData[0][data] === "No Data" ? <>
                            <NotAvailableIcon className='tooltip-icon'/>
                            <div className="custom-tooltip-icon custom-tooltip" style={{width: '110px'}}>
                                {getTranslation("Data Not Available")}
                            </div>
                        </> : existingData[0][data]
                })
                let newFieldValue = {
                    ...existingData[0],
                    "canRevert": returnCanRevert,
                    "equipment_state":equipment_state
                }
                form.setFieldsValue(newFieldValue)
                dataRef.current.map(obj => {
                    if(obj['equipment'] === newFieldValue['equipment']){
                        obj['canRevert'] = newFieldValue['canRevert']
                        obj['equipment_state'] = newFieldValue['equipment_state'] || "";
                    }
                })
                forceUpdate()
            }
        } catch (err) {
            console.error(err)

        }
    }

    const revertRecord = async(record, canRevert) => {
        if(isControlOverride && !canRevert && !clientEquipmentMapping?.data?.lastWriteWinsEnabled ){
            setRevertRecord(record)
            setIsDeleteOverrideVisible(true)    
        } else if((clientEquipmentMapping?.data?.lastWriteWinsEnabled && !canRevert) || (!isControlOverride && canRevert)){
            setRevertRecord(record)
            setShowRevertModal(true)
        } 
    }

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        // properties,
        record,
        index,
        children,
        ...restProps
    }) => {
        const data = form.getFieldsValue(true);
        const inputNode = inputType === 'Number' ? 
            <CustomInputNumber data={data} properties={record ? {...record?.[`${dataIndex}_Properties`]?.[DEFAULT_UNIT], ...record?.[`${dataIndex}_Properties`]} : []} dataIndex={dataIndex} apiResponse={apiResponse} form={form}/> : 
            <CustomDropDown apiResponse={apiResponse} dataIndex={dataIndex} form={form} setDropDownValues={setDropDownValues} properties={record ? {...record?.[`${dataIndex}_Properties`]?.[DEFAULT_UNIT], ...record[`${dataIndex}_Properties`]} : []}/>;
        return (
            <td {...restProps}>
            {editing && inputType ? (
                <Form.Item
                name={dataIndex}
                style={{
                    margin: 0,
                }}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
            </td>
        );
    };

    const edit = (record, isEditDisabled, isDisabledTime, canRevert) => {
        if(isEditDisabled){
            let endtime = moment().unix()
            let diffInTime = 30 - Number(moment.unix(endtime).diff(moment.unix(isDisabledTime), "seconds"))
            toast.error(`Send a new request after ${diffInTime} seconds`, {icon: toastError()})
            return
        }
        if(isControlOverride && !(!!clientEquipmentMapping?.data?.lastWriteWinsEnabled || canRevert)){
            return
        }

        let requiredData = streamData['widgets']
        let requiredRecord = {}
        for(let index = 0 ; index < requiredData.length ; index++){
            let obj = requiredData[index]
            let originalData = record[obj['name']]
            let availableProperties = record[`${obj['name']}_Properties`]?.[DEFAULT_UNIT] || record[`${obj['name']}_Properties`]
            let possibleValue = typeof(availableProperties?.['min_measured']) === "number" ? availableProperties['min_measured'] : 
                availableProperties?.['false_string'] ? "OFF" : availableProperties?.['inputs'] ? "Manual" :
                availableProperties?.['input_string_list']?.[0] || "NA"
            let requiredValue;
            if(originalData && typeof(originalData) === "string" && !originalData.includes("NA") && !availableProperties?.input_string_list?.includes(originalData)
                && originalData !== "Auto" && originalData !== "Manual" && originalData !== "ON" && originalData !== "OFF"){
                requiredValue = Number(originalData.split(" ")[0])
            }else if(typeof(originalData) === "object"){
                requiredValue = possibleValue
            } else
                requiredValue = originalData
            requiredRecord = {
                ...requiredRecord,
                [obj['name']]: requiredValue
            }
        }   
        form.setFieldsValue({
            ...record,
            ...requiredRecord
        });
        setEditingKey(record.key);
        editingKeyRef.current = record.key
    };

    const init = async () => {
        try{

            let nodeOverrideDuration;
            if(clientEquipmentMapping?.data?.lastWriteWinsEnabled){
                for(let i = 0; i < clientEquipmentMapping?.data?.clientSet?.edges?.length; i++){
                    let node = clientEquipmentMapping.data.clientSet.edges[i]?.node;
                    if(node?.clientName === cpmReducer.siteName){
                        nodeOverrideDuration = node?.overrideDuration
                        siteOverrideDuration.current = nodeOverrideDuration
                        break;
                    }
                }
            }
            form.resetFields();
            let param = {
                client_name: cpmReducer.siteName,
                equipment_type: equipmentType
            }
            setLoading(true)
            let response = await getSiteLiveStatus(param)
            let equipmentNameArr = [], requiredTableData = []
            if(response && response.data){
                response.data = response.data?.filter(item=>!!item?.cpm_enabled)
                setAPIResponse(response.data);
                let unsortedData = response.data
                unsortedData.sort(function(a,b){
                    if(a.display_name < b.display_name)
                        return -1;
                    else if(a.display_name > b.display_name)
                        return 1
                    else
                        return 0
                })
                for(let index = 0 ; index < response.data.length ; index++){
                    let obj = response.data[index]
                    let equipment_name = obj['display_name']
                    let equipment_state = obj['state'] || "";
                    let equipment = obj['name']
                    let canRevert = isControlOverride ? !obj['override_status'] : obj['indefinite']
                    equipmentNameArr.push(equipment_name)
                    for(let i = 0 ; i < obj['current_status'].length ; i++){
                        let currentStatusData = obj['current_status'][i];
                        let statusData
                        if(currentStatusData.hasOwnProperty("live_device_mode")){
                            statusData = currentStatusData['live_device_mode']
                        }
                        let streamName = currentStatusData['name']
                        let streamValue = currentStatusData['value']
                        let streamType = currentStatusData['type'];
                        let streamUnit = currentStatusData['unit'] === "Celsius" ? "°C" : currentStatusData['unit']
                        let streamProperties = currentStatusData['properties']
                        let toggleData
                        if(currentStatusData.hasOwnProperty('toggle'))
                            toggleData = currentStatusData['toggle']
                        let toggleStreamName, toggleStreamValue, toggleStreamUnit = '', toggleStreamProperties
                        if(toggleData){
                            toggleStreamName = toggleData['name']
                            toggleStreamValue = toggleData['value']
                            toggleStreamUnit = toggleData['unit'] === "Celsius" ? "°C" : toggleData['unit']
                            toggleStreamProperties = toggleData['properties']
                        }
                        for(let k = 0 ; k < streamData.widgets.length ; k++){
                            let widgetData = streamData.widgets[k]
                            if(widgetData['title'] === streamName && streamName.includes("ON/OFF") && streamValue !== "No Data" && streamValue !== "Old Data"){
                                widgetData['value'] = streamType.includes("monitoring") && isNaN(streamValue)  ? streamValue :  Number(streamValue)
                                widgetData['properties'] = streamProperties
                            } else if(widgetData['title'] === streamName && (!streamProperties?.input_string_list?.includes(streamValue) && streamValue !== "No Data" 
                                && streamValue !== "Old Data" && streamValue !== "Auto" && streamValue !== "Manual" && streamValue !== "ON" && streamValue !== "OFF")){
                                widgetData['value'] = streamType.includes("monitoring") && isNaN(streamValue) ? streamValue :`${ Number(streamValue)} ${streamUnit}`
                                widgetData['properties'] = streamProperties
                            } else if(widgetData['title'] === streamName && (streamProperties?.input_string_list?.includes(streamValue) || streamValue === "ON" || 
                                streamValue === "No Data" || streamValue === "Old Data" || streamValue === "Auto" || streamValue === "Manual" || streamValue === "OFF")){
                                widgetData['value'] = streamValue
                                widgetData['properties'] = streamProperties
                            } else if(widgetData['title'] === toggleStreamName  && toggleStreamValue !== "No Data" && toggleStreamValue !== "Old Data"){
                                widgetData['value'] = streamType.includes("monitoring") && isNaN(toggleStreamValue)  ? toggleStreamValue : `${Number(toggleStreamValue)} ${toggleStreamUnit}`
                                widgetData['properties'] = toggleStreamProperties
                            } else if(widgetData['title'] === toggleStreamName  && (toggleStreamValue === "No Data" || toggleStreamValue === "Old Data")){
                                widgetData['value'] = toggleStreamValue
                                widgetData['properties'] = toggleStreamProperties
                            } else if(widgetData['name'].includes("Status") && statusData && (streamName.includes("VFD") || streamName.includes("Set Pressure") || streamName.includes("Approach Set Temperature") || streamName.includes("Set Differential Pressure")) && (widgetData['title'].includes("VFD") || widgetData['title'].includes("Set Pressure") || widgetData['title'].includes("Set Differential Pressure"))) {
                                widgetData['value'] = statusData
                            } else if(widgetData['name'].includes("Status") && statusData && !streamName.includes("VFD") && !widgetData['title'].includes("VFD") && !streamName.includes("Set Pressure")) {
                                widgetData['value'] = statusData
                            } 
                        }
                    }
                    let requiredTableObj = {'key': index.toString(), equipment_name, equipment, canRevert, equipment_state}
                    for(let i = 0 ; i < streamData.widgets.length ; i++){
                        let obj = streamData.widgets[i];
                        requiredTableObj = {...requiredTableObj, [obj['name']]: (!obj['value'] && obj['value'] !== 0) ? 'NA' : !(obj['value'] === "Old Data" || obj['value'] === "No Data") ? 
                            (obj['name'].includes("ON_OFF") && !obj['type'].includes("monitoring"))  ? (obj['value']) === 1 ? "ON" : "OFF" : (obj['value']) : <>
                                <NotAvailableIcon className='tooltip-icon'/>
                                <div className="custom-tooltip-icon custom-tooltip" style={{width: '110px'}}>
                                    {getTranslation("Data Not Available")}
                                </div>
                            </>, [`${obj['name']}_Properties`]: streamData.widgets[i]['properties']
                            }
                    }
                    requiredTableData.push(requiredTableObj)
                    for(let i = 0 ; i < streamData.widgets.length ; i++){
                        let obj = streamData.widgets[i];
                        obj['value'] = null
                    }
                }
                setData(requiredTableData)
                dataRef.current = requiredTableData
            }

            let requiredColumns = [{
                title: 'Equipment Name',
                dataIndex: 'equipment_name',
                width: `${((streamData.widgets.length + 3) / 100) * 2.5}`,
                editable: false,
                filters: equipmentNameArr.map(data => {
                    return { 'text': getTranslation(data), 'value': getTranslation(data) }
                }),
                onFilter: (value, record) => record.equipment_name === value,
                filterIcon: <img src={DownArrowOutlined} alt="down-arrow" width={'16px'} style={{marginLeft: '5px', boxShadow: '0px 3px 6px #00000029', padding: '2px 2px 1px', fontSize: '10px', borderRadius:'3px'}} />
            }];
            for(let index = 0 ; index < streamData.widgets.length ; index++){
                let dataInWidget = streamData.widgets[index]
                let requiredObj = {
                    title: (dataInWidget['title'] === "Set Temperature" && streamData.equipmentType === "ahu") ? "Valve Set Temperature" : dataInWidget['title'],
                    dataIndex: dataInWidget['name'],
                    width: `${(streamData.widgets.length + 3) / 100}`,
                    editable: true,
                    inputType: dataInWidget['type'].includes("Continuous") ? 'Number' : !dataInWidget['type'].includes("monitoring") ? 'Select' : '',
                    // properties: dataInWidget['properties']
                }
                requiredColumns.push(requiredObj)
            }

            if(clientEquipmentMapping?.data?.lastWriteWinsEnabled){
                requiredColumns.push(
                    {
                        title: 'Control Type',
                        dataIndex: 'equipment_state',
                        width: `${((streamData.widgets.length + 3) / 100) * 2.5}`,
                        editable: false,
                    }
                )
            }

            requiredColumns.push({
                title: '',
                dataIndex: 'operation',
                render: (_, record) => {
                    const editable = isEditing(record);
                    let canRevert = record['canRevert']
                    let isEditDisabled = record.hasOwnProperty("isEditDisabled") ? record['isEditDisabled'] : false
                    let isDisabledTime = record.hasOwnProperty("isDisabledTime") ? record['isDisabledTime'] : false
                    return editable ? (
                    <div className="d jc-c ai-center" style={{gridGap:'3px'}}>
                        <span style={{position:'relative', width:'50%'}}>
                            <div className="tooltip-icon">
                                <Typography.Link onClick={() => save(record.key)} >
                                    <div className='table-icons' style={{width:'96%',color:'white !important',background: (!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider') ? '#009530' : dashboard?.customerTheme === 'mcdonalds' ? '#da291c' : '#F44067'}}>
                                        {!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <SaveIconForSE /> : dashboard?.customerTheme === 'mcdonalds' ? <SaveIconForMCD /> : <SaveIcon />}
                                    </div>
                                </Typography.Link>
                            </div>
                            <div className="custom-tooltip-icon custom-tooltip" style={{width:'35px'}}>{getTranslation("Save")}</div>
                        </span>
                        <span style={{position:'relative', width:'50%'}}>
                            <div className="tooltip-icon">
                                <Typography.Link onClick={cancel} >
                                <div className='table-icons' style={{width:'98%',color:'#F44067 !important',background:'white'}}>
                                {!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <img src={CloseOutlinedSE} alt="close-icon" /> : 
                                dashboard?.customerTheme === 'mcdonalds' ? <img src={CloseOutlinedMCD} alt="close-icon" /> : <img src={CloseOutlined} alt="close-icon" />}
                                </div>
                            </Typography.Link>
                            </div>
                            <div className="custom-tooltip-icon custom-tooltip" style={{width:'46px'}}>{getTranslation("Cancel")}</div>
                        </span>
                    </div>
                    ) : (
                        <div className="d jc-c ai-center" style={{gridGap:'8px'}}>
                            <span style={{position:'relative'}}>
                                <div className="tooltip-icon" style={((!isControlOverride && !isEditDisabled) || (isControlOverride && (!!clientEquipmentMapping?.data?.lastWriteWinsEnabled || canRevert))) ? {opacity:'1'} : {opacity: '0.5'}}>
                                    <div onClick={() => edit(record, isEditDisabled, isDisabledTime, canRevert)}>
                                    {!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <EditIconInstantControlForSE className="action-icons" /> : 
                                    dashboard?.customerTheme === 'mcdonalds' ? <EditIconInstantControlForMCD className="action-icons" /> : <EditIconInstantControl className="action-icons" />}
                                    </div>
                                </div>
                                <div className="custom-tooltip-icon custom-tooltip" style={(!isControlOverride || (isControlOverride && (!!clientEquipmentMapping?.data?.lastWriteWinsEnabled || canRevert))) ? {width:'29px'} : {width: '140px', left: '-35px', lineHeight: '15px'}}>
                                    {(!isControlOverride || (isControlOverride && (!!clientEquipmentMapping?.data?.lastWriteWinsEnabled || canRevert))) ? getTranslation('Edit') : getTranslation('Please delete the existing override in order to add a new override')}
                                </div>
                            </span>
                            {
                                isControlOverride ? 
                                    <>
                                        <span style={{position:'relative'}}>
                                            <div className="tooltip-icon">
                                                <div onClick={() => refreshRecord(record)}>
                                                    {!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <RefreshIconForSE className="action-icons" /> : 
                                                    dashboard?.customerTheme === 'mcdonalds' ? <RefreshIconForMCD className="action-icons" /> : <RefreshIcon className="action-icons" />}
                                                </div>
                                            </div>
                                            <div className="custom-tooltip-icon custom-tooltip" style={{width:'50px', left: '-5px'}}>
                                            {getTranslation("Refresh")}
                                            </div>
                                        </span>

                                        {!!clientEquipmentMapping?.data?.lastWriteWinsEnabled   ? 
                                            <span style={{position:'relative'}}>
                                                <div className="tooltip-icon" style={!canRevert ? {opacity:'1'} : {opacity: '0.5'}}>
                                                    <div onClick={() => revertRecord(record, canRevert)}>
                                                        {!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <UndoIconForSE className="action-icons"/> : 
                                                        dashboard?.customerTheme === 'mcdonalds' ? <UndoIconForMCD className="action-icons"/> : <UndoIcon className="action-icons"/>}
                                                    </div>
                                                </div>
                                                <div className="custom-tooltip-icon custom-tooltip" style={canRevert ? {width:'65px', lineHeight:'16px', left: '-10px'} : {width:'75px', lineHeight:'16px', left: '-40px'}}>
                                                    {(!canRevert) ? getTranslation('Revert to Schedules') : getTranslation('No Override to revert')}
                                                </div>
                                            </span>
                                            :
                                            <span style={{position:'relative'}}>
                                                <div className="tooltip-icon" style={!canRevert ? {opacity:'1'} : {opacity: '0.5'}}>
                                                    <div onClick={() => revertRecord(record, canRevert)}>
                                                        {!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <DeleteOverrideIconForSE className="delete-antd-icon" /> : 
                                                        dashboard?.customerTheme === 'mcdonalds' ? <DeleteOverrideIconForMCD className="delete-antd-icon" /> : <DeleteOverrideIcon className="delete-antd-icon"/>}
                                                    </div>
                                                </div>
                                                <div className="custom-tooltip-icon custom-tooltip" style={!canRevert ? {width:'55px', lineHeight:'16px', left: '-10px'} : {width:'75px', lineHeight:'16px', left: '-40px'}}>
                                                    {(!canRevert) ? getTranslation('Delete Override') : getTranslation('No Override to revert')}
                                                </div>
                                            </span>
                                        }
                                    </> : 
                                    <>
                                        <span style={{position:'relative'}}>
                                            <div className="tooltip-icon" style={canRevert ? {opacity:'1'} : {opacity: '0.5'}}>
                                                <div onClick={() => revertRecord(record, canRevert)}>
                                                    {!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <UndoIconForSE className="action-icons"/> : 
                                                    dashboard?.customerTheme === 'mcdonalds' ? <UndoIconForMCD className="action-icons"/> : <UndoIcon className="action-icons"/>}
                                                </div>
                                            </div>
                                            <div className="custom-tooltip-icon custom-tooltip" style={canRevert ? {width:'65px', lineHeight:'16px', left: '-10px'} : {width:'75px', lineHeight:'16px', left: '-40px'}}>
                                                {(canRevert) ? getTranslation('Revert to Schedules') : getTranslation('No Override to revert')}
                                            </div>
                                        </span>
                                        <span style={{position:'relative'}}>
                                            <div className="tooltip-icon">
                                                <div onClick={() => refreshRecord(record)}>
                                                    {!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <RefreshIconForSE className="action-icons" /> : 
                                                    dashboard?.customerTheme === 'mcdonalds' ? <RefreshIconForMCD className="action-icons" /> : <RefreshIcon className="action-icons" />}
                                                </div>
                                            </div>
                                            <div className="custom-tooltip-icon custom-tooltip" style={{width:'50px', left: '-5px'}}>
                                            {getTranslation("Refresh")}
                                            </div>
                                        </span>
                                    </>
                            }
                    </div>
                    );
                },
            })
            columns.current = requiredColumns
            setLoading(false)
        }catch (err) {
            console.log(err)
        }
    }

    const cancel = () => {
        setEditingKey('');
        editingKeyRef.current = ''
    };
    

    useEffect(()=>{
        init()
        cancel()
    },[])

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...dataRef.current];
            const index = newData.findIndex((item) => key === item.key);
            const dataForUnit = newData[index]

            let equipmentName = dataForUnit['equipment']

            for(let index = 0 ; index < Object.keys(row).length ; index++){
                let key = Object.keys(row)[index]
                let requiredUnit
                streamData['widgets'].map(obj => {
                    if(obj['name'] === key && obj['type'].includes("Continuous"))
                        requiredUnit = obj['unit']
                })
                if(requiredUnit)
                    row[key] = `${row[key]} ${requiredUnit}`
            }

            let tempState = {}
            streamData['widgets'].map(obj => {
                 if(!obj?.type?.includes('monitoring')){
                    tempState = {...tempState, [obj['title']]: row[obj['name']]}
                 }
            })
            if((tempState["ON/OFF Control"] === "OFF" || (tempState["ON/OFF Control Pump 1"] === "OFF" && tempState["ON/OFF Control Pump 2"] === "OFF") || tempState["ON/OFF Control Fan"] === "OFF" ||
                (tempState["ON/OFF Control Fan 1"] === "OFF" && tempState["ON/OFF Control Fan 2"] === "OFF"))){
                Object.keys(tempState).forEach(key => {
                    if(!key.includes("ON/OFF") || (key.includes("ON/OFF") && tempState[key] === "NA"))
                        delete tempState[key]
                    else    
                        tempState[key] = tempState[key] === "ON" ? "1" : tempState[key] === "OFF" ? "0" : tempState[key] 
                })
            }else if(tempState["Operating Mode"] === "OFF") {
                Object.keys(tempState).forEach(key => {
                    if(key !== "Operating Mode")
                        delete tempState[key]
                })
            } else{
                Object.keys(tempState).forEach(key => {
                    if(key.includes("ON/OFF") && tempState[key] !== "NA")
                        tempState[key] = tempState[key] === "ON" ? "1" : "0"
                    if(tempState[key].split(" ")[0] === "NA")
                        delete tempState[key]
                })

                if(tempState["VFD Status"] === "Auto")
                    delete tempState["VFD Control"]
                else if(tempState["VFD Status"] === "Manual"){ 
                    tempState["VFD Set Temperature"] && delete tempState["VFD Set Temperature"]
                    tempState["Supply Set Pressure"] && delete tempState["Supply Set Pressure"]
                    tempState["Set Differential Pressure"] && delete tempState["Set Differential Pressure"]
                    tempState["Set Pressure"] && delete tempState["Set Pressure"]
                    tempState["Approach Set Temperature"] && delete tempState["Approach Set Temperature"]
                }
                
                if(tempState["Valve Status"] === "Auto")
                    delete tempState["CHW Valve Control"]
                else if(tempState["Valve Status"] === "Manual")
                    delete tempState["Set Temperature"]

                if(row["ON_OFF_Control"] === "NA")
                    delete tempState["ON/OFF Control"]
                
                tempState["VFD Status"] && delete tempState["VFD Status"]
                tempState["Valve Status"] && delete tempState["Valve Status"]
            }
            Object.keys(tempState).forEach(key => {
                tempState[key] = tempState[key]?.split(" ")[0]
            })
            let states = (tempState)
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                'event': 'control override',
                'source': 'Schedule Dashboard',
                'uuid': window.localStorage.getItem('uuid'),
                'time': moment().format("YYYY-MM-DD HH:mm:ss"),
                'userId': userData.userId,
                'customer': userData.customer,
                'userType': isStaff ? 'Internal' : 'External'
            })
            let res;
            if(isControlOverride && ( !!clientEquipmentMapping?.data?.lastWriteWinsEnabled ? (siteOverrideDuration.current === 0 ? false : true)  :  true )){
                setOverrideDurationModalShow(true)
                setStateForOverride(states)
                setEquipmentForOverride(equipmentName)
            } else {
                const payload = { equipment_name: equipmentName, desired_states: states, client_name: cpmReducer.siteName}
                if(clientEquipmentMapping?.data?.lastWriteWinsEnabled && siteOverrideDuration.current === 0){
                    payload['override_expiry'] = siteOverrideDuration.current;
                }
                res = await sendInstantControl( payload, appPermissions.isDemoUser )
                if (res && res.status) {
                    toast.success(res.message || "Changes saved, please refresh after 2-3 minutes to view the changes", {icon: toastSuccess()});
                    dataRef.current.map(obj => {
                        if(obj['equipment'] === equipmentName){
                            obj['isEditDisabled'] = true,
                            obj['isDisabledTime'] = moment().unix()
                        }
                    })
                    setTimeout(() => {
                        dataRef.current.map(obj => {
                            if(obj['equipment'] === equipmentName){
                                obj['isEditDisabled'] = false
                            }
                        })
                        forceUpdate()
                    }, 30000);
                } 
                setEditingKey('');
                editingKeyRef.current = ''
                forceUpdate()
            }
        } catch (errInfo) {
            console.log(errInfo);
        }
    };
    
    const handleDeleteOverrideOk = async () => {
        try {
            let res = await deleteInstantControl({ equipment_name: revertRecordObj.equipment, client_name: cpmReducer.siteName }, appPermissions.isDemoUser)
            if (res && res.status) {
                toast.success("Override deleted successfully, please refresh after 2-3 minutes to view the changes", {icon: toastSuccess()})
                await refreshEquipment(revertRecordObj.equipment)
            } else {
                toast.error(res.message || "No changes were saved, please try after sometime", {icon: toastError()})
            }
        } catch (e) {
            console.error(e)
        }
        setRevertRecord({})
        setIsDeleteOverrideVisible(false)
    }

    const handleDeleteOverrideCancel = () => {
        setIsDeleteOverrideVisible(false)
    }

    const deleteOverrideModalFooter = [
        <Button
            key="submit"
            type="primary"
            className="delete-override-modal accept-modal-btn"
            onClick={handleDeleteOverrideOk}
        >
            {getTranslation("Yes")}
        </Button>,
        <Button
            key="back"
            className="delete-override-modal cancel-modal-btn"
            onClick={handleDeleteOverrideCancel}
        >
            {getTranslation("No")}
        </Button>
    ]

    const handleOverrideDurationOk = async () => {
        if(!overrideDurationType)
            return;
        try{
            let expiry = overrideDurationType === "indefinite" ? false : true
            const payload = { equipment_name: equipmentForOverride, desired_states: (stateForOverride), client_name: cpmReducer.siteName};
            if(clientEquipmentMapping?.data?.lastWriteWinsEnabled && siteOverrideDuration.current){
                payload['override_expiry'] = overrideDurationType;
            }
            else{
                payload["expiry"] = expiry;
            }
            let res = await sendInstantControl( payload, appPermissions.isDemoUser )
            if (res && res.status) {
                toast.success("Changes saved, please refresh after 2-3 minutes to view the changes", {icon: toastSuccess()});
                refreshEquipment(equipmentForOverride)
            } else {
                toast.error(res.message || "No changes were saved, please try after sometime", {icon: toastError()})
            }
        } catch (e) {
            console.log(e);
        }
        setOverrideDurationModalShow(false)
        setEditingKey('');
        editingKeyRef.current = ''
        setStateForOverride({})
        setEquipmentForOverride(null)
        setOverrideDurationType(null)
    }

    const overrideDurationModalFooter = [
        <Button
            key="submit"
            type="primary"
            className="override-duration-modal save-modal-btn"
            onClick={handleOverrideDurationOk}
        >
            {getTranslation("Save")}
        </Button>
    ]

    const handleOverrideDurationSelectChange = (selectedValue) => {
        setOverrideDurationType(selectedValue)
    }

    const forceUpdate = useForceUpdate()

    const handleRevertModalOk = async () => {
        try {
            let res = await deleteInstantControl({ equipment_name: revertRecordObj.equipment, client_name: cpmReducer.siteName }, appPermissions.isDemoUser)
            if (res.status) {
                toast.success((res.message || "Override deleted successfully, please refresh after 2-3 minutes to view the changes"), {icon: toastSuccess()})
                await refreshEquipment(revertRecordObj.equipment)
            } else {
                toast.error(res.message, {icon: toastError()})
            }
        } catch (e) {
            console.error(e)
        }
        setRevertRecord({})
        setShowRevertModal(false)
    };

    const handleRevertModalCancel = () => {
        setShowRevertModal(false)
    };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={translateDatasource(data, getTranslation)}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="instant-control-table"
          pagination={data.length > 10 ? { showSizeChanger: false } : false}
        />
      </Form>

      <Modal
        open={showRevertModal}
        className={`${
          !_isEmpty(dashboard && dashboard?.customerTheme) &&
          ((dashboard?.customerTheme === 'schneider' && 'SE-theme-color') || (dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color'))
        }`}
        onOk={handleRevertModalOk}
        onCancel={handleRevertModalCancel}
        closable={false}
        okText={getTranslation('Yes')}
        cancelText={getTranslation('No')}
      >
        {getTranslation('Are you sure you want to revert to schedule ?')}
      </Modal>
      <Modal
        open={isDeleteOverrideVisible}
        closable={false}
        footer={deleteOverrideModalFooter}
        centered={true}
        className={`delete-override-modal ${
          !_isEmpty(dashboard && dashboard?.customerTheme) &&
          ((dashboard?.customerTheme === 'schneider' && 'SE-theme-color') || (dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color'))
        }`}
      >
        {getTranslation('Are you sure you want')} <br /> {getTranslation('to delete this override ?')}
      </Modal>
      <Modal
        open={overrideDurationModalShow}
        closable={true}
        closeIcon={
          <img
            src={CloseBlackOutlined}
            className="cur"
            height={20}
            width={20}
            style={{ marginTop: '-2px' }}
            alt="close-icon"
            onClick={() => setOverrideDurationModalShow(false)}
          />
        }
        onCancel={() => setOverrideDurationModalShow(false)}
        footer={overrideDurationModalFooter}
        centered={true}
        className={`override-duration-modal ${
          !_isEmpty(dashboard && dashboard?.customerTheme) &&
          ((dashboard?.customerTheme === 'schneider' && 'SE-theme-color') || (dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color'))
        }`}
        title={getTranslation('Override Duration')}
      >
        <Select
          className="override-duration-selectbox"
          onChange={handleOverrideDurationSelectChange}
          suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
          placeholder={getTranslation('Select Duration')}
          value={overrideDurationType}
        >
          {clientEquipmentMapping?.data?.lastWriteWinsEnabled && !!siteOverrideDuration.current ? (
            <>
              {SITE_OVERRIDE_DURATIONS.map((duration) => (
                <Select.Option key={duration} value={duration}>
                  {duration / 60} {getTranslation('minutes')} {duration === siteOverrideDuration.current && `(${getTranslation('Default')})`}
                </Select.Option>
              ))}
              {!SITE_OVERRIDE_DURATIONS.includes(siteOverrideDuration.current) && (
                <Select.Option value={siteOverrideDuration.current}>
                  {siteOverrideDuration.current / 60} {getTranslation('minutes')} ({getTranslation('Default')})
                </Select.Option>
              )}
            </>
          ) : (
            <>
              {userData.appPermissions.regularOveride && (
                <Select.Option value={overrideDuration / 60}>
                  {overrideDuration / 60} {getTranslation('minutes')}
                </Select.Option>
              )}
              {userData.appPermissions.indefiniteOverride && <Select.Option value="indefinite">{getTranslation('Indefinite Period')}</Select.Option>}
            </>
          )}
        </Select>
      </Modal>
    </>
  );
}


export default EquipmentTable